import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoLayout = ({ className }) => {
  const {
    datoCmsGlobal: { logo },
  } = useStaticQuery(graphql`
    query headerLogo {
      datoCmsGlobal {
        logo {
          fluid(maxWidth: 400) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  `)

  const aspectRatio = logo.fluid.aspectRatio

  return (
    <div className={className}>
      <Link to="/">
        <Img
          style={{ width: `${aspectRatio >= 3 ? aspectRatio * 50 : aspectRatio * 100}px` }}
          fluid={logo.fluid}
          alt="Logo"
        />
      </Link>
    </div>
  )
}

const Logo = styled(LogoLayout)`
  min-width: 125px;
  height: auto;
  margin: 0 1rem;

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}px) {
    min-width: ${(props) => props};
    z-index: 1;
  }
`

export default Logo
