import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const CopyrightLayout = ({ className }) => {
  const {
    datoCmsGlobal: { businessName },
  } = useStaticQuery(graphql`
    query siteTitle {
      datoCmsGlobal {
        businessName
      }
    }
  `)

  const [year, setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    const clientYear = new Date().getFullYear()
    if (year !== clientYear) setYear(clientYear)
  }, [year])

  return (
    <div className={className}>
      Copyright &copy; {year} <strong>{businessName}</strong>
    </div>
  )
}

const Copyright = styled(CopyrightLayout)`
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.5rem 0;
  background-color: ${({ theme }) => theme.black};
`

export default Copyright
