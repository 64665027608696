import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const buttonTemplate = ({ className, link, children, ...props }) => {
  const isAbsolute = /(?:^[a-z][a-z0-9+.-]*:|\/\/)/gim

  return isAbsolute.test(link) ? (
    <OutboundLink className={className} href={link} {...props}>
      {children}
    </OutboundLink>
  ) : (
    <Link className={className} to={link} {...props}>
      {children}
    </Link>
  )
}

const Button = styled(buttonTemplate)`
  display: inline-block;
  text-decoration: none;
  white-space: normal;
  padding: 0.75rem 1rem;
  text-align: center;
  font-size: 1.2rem;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.contrast};
  transition: all 0.4s ease-out;
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.dark};
    color: ${({ theme }) => theme.light};
  }
`
const DarkButton = styled(Button)`
  background-color: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.light};
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.contrast};
  }
`
const GhostButton = styled(Button)`
  background-color: transparent;
  color: inherit;
  border: 2px solid ${({ theme }) => theme.primary};
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.contrast};
  }
`

export { Button, DarkButton, GhostButton, buttonTemplate as NavLink }
