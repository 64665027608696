import React from "react"
import styled from "styled-components"
import { v4 } from "uuid"

import Icon from "../atoms/Icon"

const PaymentMethodLayout = ({ className, paymentMethods }) => {
  const shouldRenderAnything = paymentMethods.length ? true : false
  if (!shouldRenderAnything) return null

  return (
    <div className={className}>
      <h3>PAYMENTS ACCEPTED</h3>
      <ul className="payment-icons">
        {paymentMethods.map((method) => {
          return (
            <li key={method.name + v4()}>
              <Icon
                symbol={method.name.toLowerCase().replace(" ", "-")}
                size="48px"
                color={({ theme }) => theme.light}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const PaymentMethods = styled(PaymentMethodLayout)`
  margin-top: 2rem;

  .payment-icons {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    flex-wrap: wrap;

    li {
      list-style: none;
      margin: 0 0.25rem;
    }
  }
`

export default PaymentMethods
