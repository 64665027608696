import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import "normalize.css"

import useTheme from "../../utilities/theme"
import GlobalStyle from "../../utilities/global"
import SVGLibrary from "../../utilities/icons"
import Header from "./Header"
import Footer from "./Footer"

const LayoutTemplate = ({ className, children }) => {
  const {
    netlifyUrl: { siteURL },
    datoCmsGlobal: { businessName, favicon },
  } = useStaticQuery(graphql`
    query SiteMeta {
      netlifyUrl {
        siteURL
      }
      datoCmsGlobal {
        businessName
        favicon {
          fixed(width: 512, height: 512) {
            src
          }
        }
      }
    }
  `)

  const theme = useTheme()
  const { googleFontAPIaddress, primary } = theme

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta name="google-site-verification" content="CLuW-m5yv36F5OrkMJttxYXomEvKfnLIVgAOdNSngvg" />
        <meta name="theme-color" content={primary} />
        <link rel="icon" type="image/png" href={favicon.fixed.src} />
        <link href={googleFontAPIaddress} rel="stylesheet" type="text/css" />
        <meta name="twitter:card" content="summary" />
        <meta property="og:site_name" content={businessName} />
        <meta property="og:url" content={siteURL} />
        <meta property="og:type" content="website" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <div className={className}>
          <GlobalStyle />
          <SVGLibrary />
          <Header />
          <main className="main-content">{children}</main>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  )
}

const Layout = styled(LayoutTemplate)`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`

export default Layout
