import { graphql, useStaticQuery } from "gatsby"
import { shade, tint, darken, opacify, complement, getContrast, rgba } from "polished"

const useThemeStyles = () => {
  const { typography, colors } = useStaticQuery(
    graphql`
      query theme {
        colors: datoCmsTheme {
          lightColor {
            hex
          }
          accentColor {
            hex
          }
          darkColor {
            hex
          }
          textColor {
            hex
          }
          fontSet
        }

        typography: datoCmsTheme {
          fontSet
        }
      }
    `
  )

  const stylingOptions = {
    fontSet: typography.fontSet,
    lightColor: colors.lightColor.hex,
    brandColor: colors.accentColor.hex,
    darkColor: colors.darkColor.hex,
    textColor: colors.textColor.hex,
  }

  const generateThemeObject = (stylingOptions) => {
    const { brandColor, lightColor, darkColor, textColor, fontSet } = stylingOptions

    const gray = (shadeValue) => darken(shadeValue / 900, "#ffffff")

    const color = (base) => (shadeValue) => {
      switch (shadeValue) {
        case 900:
          return shade(0.3, base)
        case 800:
          return shade(0.5, base)
        case 700:
          return shade(0.7, base)
        case 600:
          return shade(0.9, base)
        case 500:
          return base
        case 400:
          return tint(0.1, base)
        case 300:
          return tint(0.3, base)
        case 200:
          return tint(0.5, base)
        case 100:
          return tint(0.7, base)
        case 50:
          return tint(0.9, base)
        default:
          return base
      }
    }

    const contrast = (base) => {
      const contrastLight = getContrast(base, lightColor)
      const contrastDark = getContrast(base, textColor)
      if (contrastLight > contrastDark) {
        return lightColor
      } else {
        return textColor
      }
    }

    const selectionBackground = (brandColor) => rgba(brandColor, 0.99)

    const determineFontStack = (fontSet) => {
      let headingFontFamily, bodyFontFamily, headingWeights

      switch (fontSet) {
        case "Distinguised":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Playfair Display", "Times", "serif"],
            ["Merriweather", "Times", "serif"],
            ["700"],
          ]
          break
        case "Elegant":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Cormorant Garamond", "Times", " serif"],
            ["Montserrat", "Arial", "Helvetica", "sans-serif"],
            ["700"],
          ]
          break
        case "Fancy":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Great Vibes", "cursive"],
            ["Raleway", "Verdana", "Arial", "Helvetica", "sans-serif"],
            ["400"],
          ]
          break
        case "Fun":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Pacifico", "cursive"],
            ["Josefin Sans", "Trebuchet MS", "Arial", "Helvetica", "sans-serif"],
            ["400"],
          ]
          break
        case "Industrial":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Oswald", "Arial", "Helvetica", "sans-serif"],
            ["Open Sans", "Arial", "Helvetica", "sans-serif"],
            ["700"],
          ]
          break
        case "Legal":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Cinzel", "Times", " serif"],
            ["Lato", "Arial", "Helvetica", "sans-serif"],
            ["700"],
          ]
          break
        case "Clean":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Karla", "Verdana", "Arial", "Helvetica", "sans-serif"],
            ["Karla", "Arial", "Helvetica", "sans-serif"],
            ["700"],
          ]
          break
        case "Striking":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Oswald", "Impact", "Arial Black", "sans-serif"],
            ["Open Sans", "Arial", "Helvetica", "sans-serif"],
            ["700"],
          ]
          break

        case "Modern":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Raleway", "Verdana", "Arial", "Helvetica", "sans-serif"],
            ["Lato", "Arial", "Helvetica", "sans-serif"],
            ["700"],
          ]
          break
        case "Rugged":
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Bungee", "Impact", "Arial Black", "sans-serif"],
            ["Roboto", "Arial", "Helvetica", "sans-serif"],
            ["400"],
          ]
          break
        default:
          ;[headingFontFamily, bodyFontFamily, headingWeights] = [
            ["Oswald", "Arial", "Helvetica", "sans-serif"],
            ["Montserrat", "Arial", "Helvetica", "sans-serif"],
            ["700"],
          ]
          break
      }

      return {
        headingFontFamily,
        bodyFontFamily,
        googleFonts: [
          {
            name: headingFontFamily[0],
            styles: headingWeights,
          },
          {
            name: bodyFontFamily[0],
            styles: ["400", "400i", "700", "700i"],
          },
        ],
      }
    }

    const { headingFontFamily, bodyFontFamily, googleFonts } = determineFontStack(fontSet)

    const makeFontStackString = (arr) => arr.map((font) => `'${font}'`).join(", ")

    const fontsToRetrieve = googleFonts
      .map((font) => `${font.name.split(" ").join("+")}:${font.styles.join(",")}`)
      .join("|")

    return {
      primary: brandColor,
      secondary: color(complement(brandColor)),
      contrast: contrast(brandColor),
      gray: gray,
      black: color(darkColor)(900),
      white: color(lightColor)(200),
      light: color(lightColor),
      dark: color(darkColor),
      text: color(textColor),
      shadow: opacify(0.5, gray(800)),
      selectionBackground: selectionBackground(brandColor),
      error: "#b00020",
      mobileBreakpoint: 500,
      tabletBreakpoint: 769,
      desktopBreakpoint: 1030,
      maxWidth: 1088,
      iconSet: "solid",
      defaultHeroImage: "/images/uploads/gratisography-outlook.jpg",
      headingFontFamily: makeFontStackString(headingFontFamily),
      bodyFontFamily: makeFontStackString(bodyFontFamily),
      googleFontAPIaddress: `https://fonts.googleapis.com/css?family=${fontsToRetrieve}&display=swap`,
    }
  }

  return generateThemeObject(stylingOptions)
}

export default useThemeStyles
