import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Icon from "../atoms/Icon"
import maybeTrack from "../../utilities/analytics"

const BannerTempate = ({ className }) => {
  const { data } = useStaticQuery(graphql`
    query phoneNumbers {
      data: datoCmsGlobal {
        phoneNumbers {
          phoneNumber
          phoneNumberLabel
        }
      }
    }
  `)

  const { phoneNumbers } = data

  const fmtPhoneNum = (num) => num.replace(/[^0-9]/g, "")

  return (
    <div className={className}>
      <div className="banner__inner">
        {phoneNumbers.map(({ phoneNumber, phoneNumberLabel }) => (
          <a
            className="banner__tel-link"
            href={`tel:${fmtPhoneNum(phoneNumber)}`}
            key={phoneNumber}
            onClick={(e) => {
              const { fbq, ga } = maybeTrack(e)
              if (fbq) fbq("track", "Contact")
              if (ga) ga("send", "event", "Contact", "call", "Banner")
            }}
          >
            <Icon symbol="phone" />
            {phoneNumberLabel} <strong>{phoneNumber}</strong>
          </a>
        ))}
      </div>
    </div>
  )
}

const Banner = styled(BannerTempate)`
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  &.dark {
    background-color: ${({ theme }) => theme.light};
    color: ${({ theme }) => theme.dark};
  }
  &.light {
    background-color: ${({ theme }) => theme.dark};
    color: ${({ theme }) => theme.light};
  }

  .banner__inner {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth}px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0.5em 0;
    .banner__tel-link {
      display: block;
      text-decoration: none;
      padding: 0.75em;
      font-weight: 400;
      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.primary};
      }
      svg {
        display: inline-block;
        margin-right: 0.5em;
        vertical-align: middle;
      }
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    .banner__inner {
      justify-content: flex-end;
      padding: 0;
    }
  }
`

export default Banner
