import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`  
  * {
    box-sizing: border-box;
  }
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.text};
    font-family: ${({ theme }) => theme.bodyFontFamily};
    font-size: 16px;
    line-height: 1.5;
  }
  body.nav-open {
    overflow: hidden;
  } 
  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.headingFontFamily};
    margin: 0 0 1rem 0;
    line-height: 1.2;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.25em;
  }
  p {
    margin: 0 0 1em 0;
  
  }
  a {
    color: inherit;
    font-weight: 700;
    text-decoration: underline solid ${({ theme }) => theme.primary};
    text-decoration-skip: ink;
    &:hover,
    &:active {
      color: ${({ theme }) => theme.primary};
    }
  }

blockquote {
  font-size: 1.25em;
  margin-left: 1rem;
  padding: 1rem;
  border-left: 2px solid ${({ theme }) => theme.primary};
  p:last-child {
    margin-bottom: 0;
  }
}

  section, header {
    background-color: ${({ theme }) => theme.light};
  }
  .dark {
    background-color: ${({ theme }) => theme.dark};
    color:${({ theme }) => theme.light};
  }
  ::selection {
    background-color: ${({ theme }) => theme.selectionBackground};
    color: ${({ theme }) => theme.contrast};
  }
  
  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    h1 {
      font-size: 3em;
    }
    h2 {
      font-size: 2em;
    }
  }
`
export default GlobalStyle
