import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import SocialIcons from "../molecules/SocialIcons"
import PaymentMethods from "../molecules/PaymentMethods"
import Copyright from "../molecules/Copyright"

const FooterLayout = ({ className }) => {
  const {
    footer,
    global: { paymentMethods, socialLinks },
  } = useStaticQuery(graphql`
    query Footer {
      footer: datoCmsFooter {
        columns {
          heading
          body: bodyNode {
            childMarkdownRemark {
              html
            }
          }
          id
        }
      }

      global: datoCmsGlobal {
        paymentMethods {
          name: method
        }
        socialLinks {
          linkUrl
          socialPlatform
        }
      }
    }
  `)

  return (
    <footer className={className}>
      <div className="footer__inner" style={{ justifyContent: footer.columns.length < 4 ? "center" : "left" }}>
        {Object.keys(socialLinks).length > 0 && (
          <div className="footer__col">
            <SocialIcons links={socialLinks} />
          </div>
        )}
        {footer.columns.map((column, idx) => {
          const { heading, body } = column
          const end = footer.columns.length - 1

          return (
            <div key={column.id} className="footer__col">
              <h3>{heading}</h3>
              <div dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }} />
              {idx === end ? <PaymentMethods paymentMethods={paymentMethods} /> : ""}
            </div>
          )
        })}
      </div>
      <Copyright />
    </footer>
  )
}

const Footer = styled(FooterLayout)`
  background-color: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.light};
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 4px solid ${({ theme }) => theme.primary};

  .footer__inner {
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 2rem 1rem;
    .footer__col {
      text-align: center;
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-bottom: 2.5em;
      img {
        max-width: 100%;
      }
      h3 {
        width: 100%;
        position: relative;
        margin-bottom: 2rem;
        font-size: 1.2rem;
        letter-spacing: 1px;
        text-transform: capitalize;
        &:after {
          content: "";
          width: 30%;
          left: 35%;
          height: 2px;
          background: ${({ theme }) => theme.primary};
          position: absolute;
          bottom: -1rem;
        }
      }
      h4 {
        font-size: 1rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.mobileBreakpoint}px) {
    .footer__inner {
      flex-flow: row wrap;
      .footer__col {
        width: 50%;
        padding: 1rem;
        margin-bottom: 2rem;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}px) {
    .footer__inner {
      flex-flow: row nowrap;
      .footer__col {
        width: 25%;
        margin-bottom: 0;
      }
    }
  }
`
export default Footer
