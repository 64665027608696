const maybeTrack = function maybeTrack(e) {
  if (typeof window === undefined) {
    return false
  }

  const fbq = typeof window.fbq !== undefined ? window.fbq : false
  const ga = typeof window.ga !== undefined ? window.ga : false

  return {
    fbq,
    ga,
  }
}

export default maybeTrack
