import React from "react"
import styled from "styled-components"

const IconLayout = ({ symbol, className }) => (
  <svg className={className}>
    <use xlinkHref={`#icon-${symbol}`}></use>
  </svg>
)

const Icon = styled(IconLayout)`
  width: ${(props) => (props.size ? props.size : "16px")};
  height: ${(props) => (props.size ? props.size : "16px")};
  fill: ${(props) => (props.color ? props.color : props.theme.primary)};
`

export default Icon
