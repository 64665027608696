import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { Button, NavLink } from "../atoms/Button"

// Component Template
const NavLayout = ({ className }) => {
  // Nav Data
  const {
    data: { navItems, ctaButtonText, ctaButtonLink },
  } = useStaticQuery(graphql`
    query NavItems {
      data: datoCmsNavigation {
        navItems: navigation {
          link
          label
          id
        }
        ctaButtonText
        ctaButtonLink
      }
    }
  `)

  const [mobileNavState, show] = useState(false)

  let mobileNavClass = mobileNavState ? " open" : " closed"

  const updateNav = () => show(!mobileNavState)

  return (
    <nav className={className}>
      <div className={`nav${mobileNavClass}`}>
        <button className="nav-mobile-open" aria_controls="nav-items" onClick={updateNav}>
          ☰
        </button>
        <div id="nav-items">
          <button className="nav-mobile-close" aria_label="menu" aria_controls="nav-items" onClick={updateNav}>
            ✕
          </button>
          {navItems.map(({ link, label, id }) => (
            <NavLink className="nav-link" onClick={updateNav} link={link} key={id}>
              <div className="nav-item">{label}</div>
            </NavLink>
          ))}
          {ctaButtonText && (
            <Button className="nav-cta" link={ctaButtonLink} onClick={updateNav}>
              {ctaButtonText}
            </Button>
          )}
        </div>
      </div>
    </nav>
  )
}

// Component Styles
const Nav = styled(NavLayout)`
  /* Global */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-self: flex-end;
  flex: 1;

  .nav {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    height: 100%;

    &.closed {
      #nav-items {
        transform: translateX(100vw);
      }
    }

    #nav-items {
      display: flex;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 9999;
      flex-flow: column;
      align-items: center;
      padding: 0;
      transition: all 0.3s ease-in;
      background-color: ${({ theme }) => theme.light};
      color: ${({ theme }) => theme.dark};
    }

    .nav-link {
      display: flex;
      align-items: center;
      flex: 0;
      text-decoration: none;
      width: 100%;
      text-decoration: none;
      padding: 1rem;
      font-weight: 400;
      &:not(:nth-last-child(2)) {
        border-bottom: 1px solid #dddddd;
      }
      &[aria-current="page"] {
        font-weight: 700;
        border-bottom: 2px solid ${({ theme }) => theme.primary};
      }
    }
    .nav-item {
      width: 100%;
      text-align: center;
      white-space: nowrap;
    }

    .nav-cta {
      margin: 1rem;
    }
  }

  .nav-mobile-open,
  .nav-mobile-close {
    position: relative;
    cursor: pointer;
    padding: 1rem;
    margin-left: auto;
    margin-right: 0.5rem;
    align-self: flex-start;
    font-size: 2rem;
    border: 0;
    background: none;
  }

  /* Desktop */
  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}px) {
    justify-content: flex-end;
    flex-grow: 1;

    .nav-mobile-open,
    .nav-mobile-close {
      display: none;
    }

    .nav {
      &.closed {
        #nav-items {
          transform: translateX(0);
        }
      }

      #nav-items {
        position: relative;
        flex-flow: row nowrap;
        align-items: stretch;
        padding: 0;
        height: 100%;
        width: 100%;
        justify-content: flex-end;
        background-color: inherit;
        color: inherit;
      }

      .nav-link {
        padding: 0 1rem;
        &:not(:nth-last-child(2)) {
          border-bottom: 0;
        }
        &[aria-current="page"] {
          border-bottom: 0;
          .nav-item {
            position: relative;
            &::after {
              content: "";
              width: 100%;
              height: 2px;
              position: absolute;
              bottom: -0.5rem;
              left: 0;
              background: ${({ theme }) => theme.primary};
            }
          }
        }
      }
      .nav-cta {
        margin-left: 1rem;
      }
    }
  }
`

export default Nav
