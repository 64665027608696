import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Logo from "../atoms/Logo"
import Nav from "../molecules/Nav"
import Banner from "../molecules/Banner"

const HeaderLayout = ({ className }) => {
  const {
    data: { version },
  } = useStaticQuery(graphql`
    query HeaderTheme {
      data: datoCmsNavigation {
        version: headerTheme
      }
    }
  `)

  return (
    <header className={version + ` ` + className}>
      <Banner className={version} />
      <div className="navigation">
        <Logo />
        <Nav />
      </div>
    </header>
  )
}

const Header = styled(HeaderLayout)`
  width: 100%;

  .navigation {
    width: 100%;
    max-width: ${({ theme }) => theme.maxWidth}px;
    height: 150px;
    padding: 25px 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
`
export default Header
