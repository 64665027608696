import React from "react"
import styled from "styled-components"

import Icon from "../atoms/Icon"
import maybeTrack from "../../utilities/analytics"

const SocialIconsLayout = ({ className, links }) => (
  <div className={className}>
    <h3>Follow Us</h3>
    <ul className="social-icons">
      {links.map(({ linkURL, socialPlatform }) => (
        <li key={socialPlatform}>
          <a
            href={linkURL}
            target="_blank"
            rel="noopener noreferrer"
            title={socialPlatform}
            onClick={(e) => {
              const { fbq, ga } = maybeTrack(e)
              if (fbq) fbq("trackCustom", "Social Visit", { platform: socialPlatform })
              if (ga) ga("send", "event", "Social Visit", "click", socialPlatform)
            }}
          >
            <Icon symbol={socialPlatform.toLowerCase()} size="32px" color={({ theme }) => theme.light} />
          </a>
        </li>
      ))}
    </ul>
  </div>
)

const SocialIcons = styled(SocialIconsLayout)`
  .social-icons {
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    flex-wrap: wrap;

    li {
      list-style: none;
      margin: 0 0.25rem;

      svg {
        transition: fill 0.2s linear;
        &:hover {
          fill: ${({ theme }) => theme.primary};
        }
      }
    }
  }
`

export default SocialIcons
